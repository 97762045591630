import React, { useEffect, useState } from 'react';
import { message, Spin, Button } from 'antd';
import categoriesApi from 'api/categoriesApi';
import { useLocation, useNavigate } from 'react-router-dom';
import styles from './CategoryPage.module.scss';
import CategoryForm from 'components/CategoryForm';

const CategoryPage = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [category, setCategory] = useState({});
  const [isLoading, setLoading] = useState(false);

  const fetchCategory = async () => {
    try {
      setLoading(true);
      const id = pathname?.split('/')[2];

      if (!id || id === 'create') {
        setCategory({});
        setLoading(false);
        return;
      }

      const response = await categoriesApi.getCategory(id);

      setCategory(response.data);
    } catch (error) {
      message.error('Error on get category');
    } finally {
      setLoading(false);
    }
  };

  const onFinish = async (values) => {
    try {
      setLoading(true);
      let response;

      if (category.id) {
        response = await categoriesApi.updateCategory(category.id, values);
      } else {
        response = await categoriesApi.createCategory(values);
      }

      setCategory(response.data);
      message.success(
        `Category has been ${category.id ? 'updated' : 'created'}`
      );
    } catch (e) {
      message.error(`Error on ${category.id ? 'update' : 'create'} category`);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCategory();
  }, []);

  useEffect(() => {
    fetchCategory();
  }, [pathname]);

  return isLoading ? (
    <Spin className={styles.spin} />
  ) : (
    <div className={styles.category}>
      <div className={styles.header}>
        <h1>{category.id ? 'Update Category' : 'Create Category'}</h1>
        <Button
          type="primary"
          onClick={() => navigate(`/categories/${category.id}/items`)}
        >
          Show Items
        </Button>
      </div>
      <CategoryForm category={category} onFinish={onFinish} />
    </div>
  );
};

export default CategoryPage;
