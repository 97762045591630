import React from 'react';
import {
  Form,
  Input,
  InputNumber,
  Button,
  Checkbox,
  Upload,
  message,
  Modal,
} from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import imagesApi from 'api/imagesApi';
import styles from './ShopForm.module.scss';

const ShopForm = ({ shop, onFinish }) => {
  const customRequest = async ({ onSuccess, onError, file }) => {
    const image = await imagesApi.generateImageUrl();

    try {
      await imagesApi.upload(image.data.uploadUrl, file);
      onSuccess(image.data.url);
    } catch (err) {
      onError(err);
      message.error('Image upload failed');
    }
  };

  const handlePreview = async (file) => {
    Modal.info({
      title: file.name,
      content: (
        <img
          alt=""
          className={styles.imagePreview}
          src={file.url || file.response}
        />
      ),
      footer: null,
      icon: null,
      centered: true,
      maskClosable: true,
      width: 'auto',
      height: 'auto',
    });
  };

  const onFormSubmit = async (values) => {
    onFinish({
      ...values,
      imageUrl: values.imageUrl?.file?.response,
      iconUrl: values.iconUrl?.file?.response,
    });
  };

  return (
    <Form
      name="shopForm"
      className={styles.form}
      labelCol={{ span: 5 }}
      onFinish={onFormSubmit}
    >
      <Form.Item
        label="Image"
        className={styles.imageUpload}
        name="imageUrl"
        initialValue={{ file: { response: shop.imageUrl } }}
        rules={[
          {
            required: true,
            validator: (rule, value) => {
              console.log(value);
              if (!value?.file || value.file.status === 'removed') {
                return Promise.reject('Image is required');
              }

              return Promise.resolve();
            },
          },
        ]}
      >
        <Upload
          accept="image/*"
          listType="picture-card"
          maxCount={1}
          customRequest={customRequest}
          onPreview={handlePreview}
          defaultFileList={shop.imageUrl ? [{ url: shop.imageUrl }] : undefined}
        >
          <div>
            <UploadOutlined />
            <div>Image</div>
          </div>
        </Upload>
      </Form.Item>
      <Form.Item
        label="Icon"
        className={styles.imageUpload}
        name="iconUrl"
        initialValue={{ file: { response: shop.iconUrl } }}
        rules={[
          {
            required: true,
            validator: (rule, value) => {
              if (!value?.file || value.file.status === 'removed') {
                return Promise.reject('Icon is required');
              }

              return Promise.resolve();
            },
          },
        ]}
      >
        <Upload
          accept="image/*"
          listType="picture-card"
          maxCount={1}
          customRequest={customRequest}
          onPreview={handlePreview}
          defaultFileList={shop.iconUrl ? [{ url: shop.iconUrl }] : undefined}
        >
          <div>
            <UploadOutlined />
            <div>Icon</div>
          </div>
        </Upload>
      </Form.Item>
      <Form.Item
        label="Name"
        className={styles.inputItem}
        name="name"
        initialValue={shop.name}
        rules={[
          {
            required: true,
            message: 'Please enter brand name',
          },
        ]}
      >
        <Input placeholder="Name" autoComplete="Name" />
      </Form.Item>
      <Form.Item
        label="URL"
        name="url"
        initialValue={shop.url}
        rules={[
          {
            required: true,
            message: 'Please enter brand URL',
          },
          {
            type: 'url',
            message: 'URL must be  valid',
          },
        ]}
      >
        <Input placeholder="URL" />
      </Form.Item>
      <Form.Item
        label="Rank"
        className={styles.inputItem}
        name="rank"
        initialValue={shop.rank || 0}
        rules={[
          { required: true, message: 'Please enter rank' },
          {
            type: 'number',
            min: 0,
            message: 'Rank must be >= 0',
          },
        ]}
      >
        <InputNumber min={0} />
      </Form.Item>
      <Form.Item
        label="Show on home page"
        labelCol={1}
        name="isDefault"
        valuePropName="checked"
        initialValue={shop.isDefault || false}
        rules={[
          {
            required: true,
            message: 'Select param',
          },
        ]}
      >
        <Checkbox />
      </Form.Item>
      <Form.Item className={styles.buttonItem}>
        <Button className={styles.button} type="primary" htmlType="submit">
          {shop.id ? 'Update' : 'Create'}
        </Button>
      </Form.Item>
    </Form>
  );
};

export default ShopForm;
