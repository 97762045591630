import { Button, Image } from 'antd';
import React from 'react';
import styles from './ProductList.module.scss';

const getColumns = (deleteAction) => [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    render: (name, { url }) => (
      url
        ? <a href={url?.startsWith('http') ? url : `https://${url}`} target="_blank" rel="noopener noreferrer">
          {name}
        </a>
        : name
    ),
  },
  {
    title: 'Image',
    dataIndex: 'imageUrls',
    key: 'image',
    render: (imageUrls) => (
      <Image.PreviewGroup items={imageUrls}>
        <Image
          src={imageUrls[0]}
          width={50}
          height={50}
          className={styles.image}
          placeholder
        />
      </Image.PreviewGroup>
    ),
  },
  {
    title: 'Action',
    key: 'action',
    render: (item) => (
      <Button type="primary" danger onClick={() => deleteAction(item)}>
        Delete
      </Button>
    ),
  },
];

export { getColumns };
