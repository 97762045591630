import React, { useState, useEffect } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { Table, message, Modal, Button } from 'antd';
import categoriesApi from 'api/categoriesApi';
import { getColumns } from './utils';
import styles from './CategoryList.module.scss';

const CategoryListPage = () => {
  const [categories, setCategories] = useState([]);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [params, setParams] = useSearchParams({
    page: 1,
    size: 20,
  });
  const navigate = useNavigate();

  const fetchCategories = async () => {
    try {
      setLoading(true);

      const response = await categoriesApi.getCategories({
        ...Object.fromEntries(params),
      });

      setCategories(response.data);
      setTotal(response.headers['x-total-count']);
    } catch (error) {
      message.error('Error on getting categories');
    } finally {
      setLoading(false);
    }
  };

  const createCategory = async () => {
    navigate('/categories/create');
  };

  const deleteCategory = async ({ id, name }) => {
    Modal.confirm({
      title: `Do you really want to delete ${name}?`,
      content: 'This action cannot be undone',
      centered: true,
      maskClosable: true,
      onOk: async () => {
        try {
          setLoading(true);
          await categoriesApi.deleteCategory(id);
          await fetchCategories();
        } catch (err) {
          message.error(`Error on deletion category ${name}`);
        } finally {
          setLoading(false);
        }
      },
      okText: 'DELETE',
      okButtonProps: {
        danger: true,
      },
    });
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  useEffect(() => {
    fetchCategories();
  }, [params]);

  return (
    <div>
      <div className={styles.header}>
        <h2>Categories</h2>
        <Button onClick={createCategory} type="primary">
          Create Category
        </Button>
      </div>
      <div>
        <Table
          columns={getColumns(deleteCategory)}
          dataSource={categories}
          rowKey="id"
          onRow={(row) => ({
            onClick: () => navigate(`/categories/${row.id}`),
          })}
          rowClassName={styles.row}
          loading={loading}
          pagination={{
            pageSize: Number(params.get('size')),
            current: Number(params.get('page')),
            total: total,
            onChange: (page, size) => {
              params.set('page', page);
              params.set('size', size);
              setParams(params);
            },
          }}
        />
      </div>
    </div>
  );
};

export default CategoryListPage;
