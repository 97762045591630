import React from 'react';
import { Form, Input, Button, Upload, message, Modal } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import imagesApi from 'api/imagesApi';
import styles from './ItemForm.module.scss';

const ItemForm = ({ isLoading, onFinish }) => {
  const [form] = Form.useForm();

  const customRequest = async ({ onSuccess, onError, file }) => {
    const image = await imagesApi.generateImageUrl('STANDARD');

    try {
      await imagesApi.upload(image.data.uploadUrl, file);
      onSuccess(image.data.url);
    } catch (err) {
      onError(err);
      message.error('Image upload failed');
    }
  };

  const handlePreview = async (file) => {
    Modal.info({
      title: file.name,
      content: (
        <img
          alt=""
          className={styles.imagePreview}
          src={file.url || file.response}
        />
      ),
      footer: null,
      icon: null,
      centered: true,
      maskClosable: true,
      width: 'auto',
      height: 'auto',
    });
  };

  const onFormSubmit = async (values) => {
    await onFinish({
      ...values,
      imageUrls: values.imageUrls?.fileList?.map(({ response }) => response),
    });
    form.resetFields();
  };

  return (
    <Form
      form={form}
      name="itemForm"
      className={styles.form}
      labelCol={{ span: 5 }}
      onFinish={onFormSubmit}
    >
      <Form.Item
        className={styles.imageUpload}
        name="imageUrls"
        rules={[
          {
            required: true,
            validator: (rule, value) => {
              if (!value?.fileList?.length) {
                return Promise.reject('Images is required');
              }

              return Promise.resolve();
            },
          },
        ]}
      >
        <Upload
          accept="image/*"
          listType="picture-card"
          customRequest={customRequest}
          onPreview={handlePreview}
        >
          <div>
            <UploadOutlined />
            <div>Image</div>
          </div>
        </Upload>
      </Form.Item>
      <Form.Item
        label="Name"
        className={styles.inputItem}
        name="name"
        rules={[
          {
            required: true,
            message: 'Please enter product name',
          },
        ]}
      >
        <Input placeholder="Name" />
      </Form.Item>
      <Form.Item
        label="Description"
        className={styles.inputItem}
        name="description"
      >
        <Input placeholder="Description" />
      </Form.Item>
      <Form.Item label="Brand" className={styles.inputItem} name="brand">
        <Input placeholder="Brand" />
      </Form.Item>
      <Form.Item label="Price" className={styles.inputItem} name="price">
        <Input placeholder="$10" />
      </Form.Item>
      <Form.Item label="Size" className={styles.inputItem} name="size">
        <Input placeholder="Size" />
      </Form.Item>
      <Form.Item label="Colour" className={styles.inputItem} name="colour">
        <Input placeholder="Colour" />
      </Form.Item>
      <Form.Item
        label="URL"
        name="url"
        rules={[
          {
            type: 'url',
            message: 'URL must be  valid',
          },
        ]}
      >
        <Input placeholder="URL" />
      </Form.Item>
      <Form.Item className={styles.buttonItem}>
        <Button
          className={styles.button}
          type="primary"
          htmlType="submit"
          loading={isLoading}
        >
          Create
        </Button>
      </Form.Item>
    </Form>
  );
};

export default ItemForm;
