import React from 'react';
import {
  UserOutlined,
  ShoppingCartOutlined,
  FlagOutlined,
  UnorderedListOutlined,
  ShoppingOutlined,
} from '@ant-design/icons';

export const MENU_ITEMS = [
  {
    key: 'u',
    icon: <UserOutlined />,
    name: 'Users',
    href: '/users',
  },
  {
    key: 'p',
    icon: <ShoppingCartOutlined />,
    name: 'Products',
    href: '/products',
  },
  {
    key: 'b',
    icon: <FlagOutlined />,
    name: 'Banners',
    href: '/banners',
  },
  {
    key: 'c',
    icon: <UnorderedListOutlined />,
    name: 'Categories',
    href: '/categories',
  },
  {
    key: 's',
    icon: <ShoppingOutlined />,
    name: 'Brands',
    href: '/shops',
  },
];
