import apiClient from './axiosClient';

const API = {
  getCategories: (params) => apiClient.get('categories', { params }),
  getCategory: (id) => apiClient.get(`categories/${id}`),
  createCategory: (body) => apiClient.post(`categories`, body),
  updateCategory: (id, body) => apiClient.put(`categories/${id}`, body),
  deleteCategory: (id) => apiClient.delete(`categories/${id}`),
  getCategoryItems: (id, params) =>
    apiClient.get(`categories/${id}/items`, { params }),
  addItemToCategory: (id, body) => apiClient.post(`categories/${id}/items`, body),
  updateItemRank: (id, body) => apiClient.put(`categories/${id}/items`, body),
  removeItemFromCategory: (id, itemId) =>
    apiClient.delete(`categories/${id}/items/${itemId}`),
  getItemRanks: (id, params) => apiClient.get(`categories/${id}/item-ranks`, { params })
};

export default API;
