import React from 'react';
import { Form, Input, Button } from 'antd';
import styles from './CategoryForm.module.scss';

const CategoryForm = ({ category, onFinish }) => {
  const onFormSubmit = async (values) => {
    onFinish(values);
  };

  return (
    <Form
      name="categoryForm"
      className={styles.form}
      labelCol={{ span: 5 }}
      onFinish={onFormSubmit}
    >
      <Form.Item
        label="Name"
        className={styles.inputItem}
        name="name"
        initialValue={category.name}
        rules={[
          {
            required: true,
            message: 'Please enter category name',
          },
        ]}
      >
        <Input placeholder="Name" autoComplete="Name" />
      </Form.Item>
      <Form.Item className={styles.buttonItem}>
        <Button className={styles.button} type="primary" htmlType="submit">
          {category.id ? 'Update' : 'Create'}
        </Button>
      </Form.Item>
    </Form>
  );
};

export default CategoryForm;
