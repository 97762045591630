import apiClient from './axiosClient';

const API = {
  getShops: (params) => apiClient.get('shops', { params }),
  getShop: (id) => apiClient.get(`shops/${id}`),
  updateShop: (id, body) => apiClient.put(`shops/${id}`, body),
  deleteShop: (id) => apiClient.delete(`shops/${id}`),
};

export default API;
