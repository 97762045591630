import React, { useEffect, useState } from 'react';
import { message, Spin } from 'antd';
import shopsApi from 'api/shopsApi';
import { useLocation } from 'react-router-dom';
import styles from './ShopPage.module.scss';
import ShopForm from 'components/ShopForm';

const ShopPage = () => {
  const { pathname } = useLocation();
  const [shop, setShop] = useState({});
  const [isLoading, setLoading] = useState(false);

  const fetchShop = async () => {
    try {
      setLoading(true);
      const id = pathname?.split('/')[2];

      if (!id || id === 'create') {
        setShop({});
        setLoading(false);
        return;
      }

      const response = await shopsApi.getShop(id);

      setShop(response.data);
    } catch (error) {
      message.error('Error on get shop');
    } finally {
      setLoading(false);
    }
  };

  const onFinish = async (values) => {
    try {
      setLoading(true);
      let response;

      if (shop.id) {
        response = await shopsApi.updateShop(shop.id, values);
      } else {
        response = await shopsApi.createShop(values);
      }

      setShop(response.data);
      message.success(`Shop has been ${shop.id ? 'updated' : 'created'}`);
    } catch (e) {
      message.error(`Error on ${shop.id ? 'update' : 'create'} shop`);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchShop();
  }, []);

  useEffect(() => {
    fetchShop();
  }, [pathname]);

  return isLoading ? (
    <Spin className={styles.spin} />
  ) : (
    <div className={styles.shop}>
      <div className={styles.header}>
        <h1>{shop.id ? 'Update Brand' : 'Create Brand'}</h1>
      </div>
      <ShopForm shop={shop} onFinish={onFinish} />
    </div>
  );
};

export default ShopPage;
