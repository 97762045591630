import { Button } from 'antd';
import React from 'react';

const getColumns = (deleteAction) => [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Action',
    key: 'action',
    render: (item) => (
      <Button
        type="primary"
        danger
        onClick={async (e) => {
          e.stopPropagation();
          await deleteAction(item);
        }}
      >
        Delete
      </Button>
    ),
  },
];

export { getColumns };
