import React, { useEffect, useState } from 'react';
import { message, Divider, Radio, Input, Modal, InputNumber } from 'antd';
import categoriesApi from 'api/categoriesApi';
import itemsApi from 'api/itemsApi';
import { useLocation } from 'react-router-dom';
import debounce from 'lodash/debounce';
import CategoryItems from 'components/CategoryItems';
import ItemForm from 'components/ItemForm';
import styles from './CategoryItemList.module.scss';

const SEARCH_DELAY = 500;

const BannerPage = () => {
  const { pathname } = useLocation();
  const [total, setTotal] = useState(0);
  const [isLoading, setLoading] = useState(false);
  const [items, setItems] = useState([]);
  const [tab, setTab] = useState('items');
  const [params, setParams] = useState({
    page: 1,
    size: 20,
    query: '',
  });
  const [rank, setRank] = useState(0);
  let rk = 0;

  const fetchItems = async () => {
    try {
      setLoading(true);
      const id = pathname?.split('/')[2];
      let response;

      if (tab === 'items') {
        response = await categoriesApi.getCategoryItems(id, {
          ...params,
          query: undefined,
        });
        const ranksResponse = await categoriesApi.getItemRanks(id, {
          itemIds: response.data.map(({ id }) => id),
        });
        const ranks = new Map(
          ranksResponse.data.map(({ itemId, rank }) => [itemId, rank])
        );
        response.data = response.data.map((item) => ({
          ...item,
          rank: ranks.get(item.id),
        }));
      } else {
        response = await itemsApi.searchItems({ ...params, isCommon: true });
      }

      setItems(response.data);
      setTotal(response.headers['x-total-count']);
    } catch (error) {
      message.error('Error on get items');
    } finally {
      setLoading(false);
    }
  };

  const onTabChange = (event) => {
    setTab(event.target.value);
    setParams({
      page: 1,
      size: 20,
      query: '',
    });
  };

  const onTableChange = (page, size) => {
    setParams((p) => ({ ...p, page, size }));
  };

  const onPositionChange = async (itemId, rank) => {
    try {
      setLoading(true);
      const id = pathname?.split('/')[2];
      await categoriesApi.updateItemRank(id, {
        itemId,
        rank,
      });

      await fetchItems();
    } catch (error) {
      message.error('Error on update item position');
    } finally {
      setLoading(false);
    }
  };

  const createItem = async (values) => {
    try {
      setLoading(true);
      await itemsApi.createItem(values);
      message.success('Item has been created');
    } catch (error) {
      message.error('Error on creating item');
    } finally {
      setLoading(false);
    }
  };

  const debouncedSearch = debounce(async (event) => {
    setParams((p) => ({ ...p, query: event.target.value }));
  }, SEARCH_DELAY);

  const addItemAction = {
    text: 'Add',
    action: (item) =>
      Modal.confirm({
        title: 'Enter rank',
        content: (
          <InputNumber
            min={0}
            value={rank}
            onChange={(r) => setRank(r)}
          ></InputNumber>
        ),
        onOk: async () => {
          try {
            setLoading(true);
            const id = pathname?.split('/')[2];

            await categoriesApi.addItemToCategory(id, {
              itemId: item.id,
              rank: rank,
            });
            setRank(0);
            message.success(`Item has been added`);
          } catch (e) {
            message.error(`Error on add item`);
          } finally {
            setLoading(false);
          }
        },
      }),
  };

  const removeItemAction = {
    text: 'Delete',
    isDelete: true,
    action: async (item) => {
      Modal.confirm({
        title: `Remove item?`,
        content: item.name,
        centered: true,
        maskClosable: true,
        onOk: async () => {
          try {
            setLoading(true);
            const id = pathname?.split('/')[2];

            await categoriesApi.removeItemFromCategory(id, item.id);
            const response = await categoriesApi.getCategoryItems(id, {
              ...params,
              query: undefined,
            });

            setItems(response.data);
            setTotal(response.headers['x-total-count']);
            message.success(`Item has been removed`);
          } catch (e) {
            message.error(`Error on remove item`);
          } finally {
            setLoading(false);
          }
        },
        okText: 'DELETE',
        okButtonProps: {
          danger: true,
        },
      });
    },
  };

  useEffect(() => {
    fetchItems();
  }, []);

  useEffect(() => {
    fetchItems();
  }, [tab, params, pathname]);

  return (
    <div className={styles.banner}>
      <div className={styles.header}>
        <h1>Category Items</h1>
      </div>

      <Divider>
        <Radio.Group className={styles.tabs} onChange={onTabChange} value={tab}>
          <Radio.Button value="items">Items</Radio.Button>
          <Radio.Button value="addNew">Add New</Radio.Button>
          <Radio.Button value="create">Create</Radio.Button>
        </Radio.Group>
      </Divider>
      {tab === 'addNew' && (
        <Input.Search
          className={styles.search}
          placeholder="Search"
          defaultValue={params.query}
          onChange={debouncedSearch}
          allowClear
        />
      )}
      {['items', 'addNew'].includes(tab) && (
        <CategoryItems
          items={items}
          total={total}
          isLoading={isLoading}
          buttonAction={tab === 'items' ? removeItemAction : addItemAction}
          onChange={onTableChange}
          showPosition={tab === 'items'}
          onPositionChange={onPositionChange}
          page={params.page}
          size={params.size}
        />
      )}
      {tab === 'create' && (
        <ItemForm isLoading={isLoading} onFinish={createItem} hasRank />
      )}
    </div>
  );
};

export default BannerPage;
