import { Button, Checkbox, Image } from 'antd';
import React from 'react';
import styles from './ShopList.module.scss';

const getColumns = (deleteAction) => [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    render: (name, item) =>
      item.url ? (
        <a
          href={item.url}
          target="_blank"
          rel="noopener noreferrer"
          onClick={(e) => e.stopPropagation()}
        >
          {name}
        </a>
      ) : (
        name
      ),
  },
  {
    title: 'Image',
    dataIndex: 'imageUrl',
    key: 'image',
    render: (imageUrl) => (
      <div className={styles.imageWrapper} onClick={(e) => e.stopPropagation()}>
        <Image
          src={imageUrl}
          width={50}
          height={50}
          className={styles.image}
          placeholder
        />
      </div>
    ),
  },
  {
    title: 'Icon',
    dataIndex: 'iconUrl',
    key: 'icon',
    render: (iconUrl) => (
      <div className={styles.imageWrapper} onClick={(e) => e.stopPropagation()}>
        <Image
          src={iconUrl}
          width={50}
          height={50}
          className={styles.image}
          placeholder
        />
      </div>
    ),
  },
  {
    title: 'Shown on home page',
    dataIndex: 'isDefault',
    key: 'isDefault',
    width: 180,
    align: 'center',
    render: (isDefault) => <Checkbox checked={isDefault} />,
  },
  {
    title: 'Rank',
    dataIndex: 'rank',
    key: 'rank',
    width: 150,
    align: 'center',
  },
  {
    title: 'Action',
    key: 'action',
    render: (item) => (
      <Button
        type="primary"
        danger
        onClick={async (e) => {
          e.stopPropagation();
          await deleteAction(item);
        }}
      >
        Delete
      </Button>
    ),
  },
];

export { getColumns };
