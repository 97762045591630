import React, { useState, useEffect } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { Table, message, Modal, Button } from 'antd';
import shopsApi from 'api/shopsApi';
import { getColumns } from './utils';
import styles from './ShopList.module.scss';

const ShopListPage = () => {
  const [shops, setShops] = useState([]);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [params, setParams] = useSearchParams({
    page: 1,
    size: 20,
  });
  const navigate = useNavigate();

  const fetchShops = async () => {
    try {
      setLoading(true);

      const response = await shopsApi.getShops({
        ...Object.fromEntries(params),
      });

      setShops(response.data);
      setTotal(response.headers['x-total-count']);
    } catch (error) {
      message.error('Error on getting shops');
    } finally {
      setLoading(false);
    }
  };

  const createShop = async () => {
    navigate('/shops/create');
  };

  const deleteShop = async ({ id, name }) => {
    Modal.confirm({
      title: `Do you really want to delete ${name}?`,
      content: 'This action cannot be undone',
      centered: true,
      maskClosable: true,
      onOk: async () => {
        try {
          setLoading(true);
          await shopsApi.deleteShop(id);
          await fetchShops();
        } catch (err) {
          message.error(`Error on deletion shop ${name}`);
        } finally {
          setLoading(false);
        }
      },
      okText: 'DELETE',
      okButtonProps: {
        danger: true,
      },
    });
  };

  useEffect(() => {
    fetchShops();
  }, []);

  useEffect(() => {
    fetchShops();
  }, [params]);

  return (
    <div>
      <div className={styles.header}>
        <h2>Brands</h2>
        <Button onClick={createShop} type="primary">
          Create Brand
        </Button>
      </div>
      <div>
        <Table
          columns={getColumns(deleteShop)}
          dataSource={shops}
          rowKey="id"
          onRow={(row) => ({
            onClick: () => navigate(`/shops/${row.id}`),
          })}
          rowClassName={styles.row}
          loading={loading}
          pagination={{
            pageSize: Number(params.get('size')),
            current: Number(params.get('page')),
            total: total,
            onChange: (page, size) => {
              params.set('page', page);
              params.set('size', size);
              setParams(params);
            },
          }}
        />
      </div>
    </div>
  );
};

export default ShopListPage;
